import 'normalize.css';
import '../styles/typography.css';

import * as React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Global, css } from '@emotion/react';

const Main = styled.main`
  display: flex;
  flex-direction: column;

  max-width: 65rem;
  padding: 2.5rem;

  &.row {
    flex-direction: row;
  }

  &.circle {
    max-width: none;
    padding: 0;
    margin-top: 5rem;
  }

  &.grid,
  &.vertical,
  &.horizontal {
    max-width: none;
    padding: 0;
  }

  max-width: 65rem;
  padding: 2.5rem;

  @media (min-width: 475px) {
    padding: 5rem;
  }
`;

interface LayoutRootProps {
  className?: string;
  title: string;
}

export const Layout: React.FC<LayoutRootProps> = ({
  children,
  className,
  title,
}) => (
  <Main className={className}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Global
      styles={css`
        html {
          width: 100%;
          height: 100%;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          font-size: 62.5%;
        }

        body {
          font-weight: normal;
          font-style: normal;

          overflow-x: hidden;
          overflow-y: scroll;
          font-family: 'silkaregular';
          color: #222324;
          background-color: #dfe0e2;

          width: 100%;
          height: 100%;
          margin: 0;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          position: relative;
        }
        pre {
          white-space: normal;
          color: #dfe0e2;
          background-color: #222324;
          font-size: 1.6rem;
          padding: 2.5rem;
          margin: 0px 0px 2.5rem;
        }

        pre::selection {
          color: #222324;
          background-color: #dfe0e2;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'silkasemibold';
          line-height: 1.2;
          text-rendering: optimizelegibility;
          letter-spacing: -0.5px;
          margin: 2.5rem 0;
          padding: 0 0 2.5rem;
          border-bottom: 1px solid #222324;
        }

        ul,
        ol {
          margin: 0;
          padding: 0;
          counter-reset: counter;
          list-style-type: none;
        }

        ul {
          margin-bottom: 2.5rem;
        }

        li {
          display: block;
          padding: 0 0 1rem;
          font-size: 1.8rem;

          counter-increment: counter;
        }

        li:before {
          content: counter(counter) ': ';
        }

        a {
          font-family: 'silkasemibold';
        }

        ::selection {
          color: #dfe0e2;
          background-color: #222324;
        }
      `}
    />
    {children}
  </Main>
);
