import tinygradient from 'tinygradient';
import tinycolor, { Instance } from 'tinycolor2';

export const getGradientColors = (
  params: { c1: string; c2: string },
  stops = 11,
): Instance[] => {
  const { c1, c2 } = params;
  let tc1 = tinycolor(c1);
  let tc2 = tinycolor(c2);
  tc1 = tc1.isValid() ? tc1 : tinycolor('#469de7');
  tc2 = tc2.isValid() ? tc2 : tinycolor('#cb2b79');

  const gradient = tinygradient([tc1, tc2]);
  const colors = gradient.rgb(stops);

  return colors;
};

export interface ColorValues {
  topLeft: string;
  topRight: string;
  bottomRight: string;
  bottomLeft: string;
}

export const getGridRows = (
  { topLeft, topRight, bottomRight, bottomLeft }: ColorValues,
  stops: number,
) => {
  // all the colors down the left side
  const leftColors = getGradientColors({ c1: topLeft, c2: bottomLeft }, stops);

  // all the colors down the right side
  const rightColors = getGradientColors(
    { c1: topRight, c2: bottomRight },
    stops,
  );

  const rowColors: Instance[][] = [];
  for (let i = 0; i < stops; i++) {
    // get the first from each side and get gradient colors
    const left = leftColors[i].toRgbString();
    const right = rightColors[i].toRgbString();
    const nextRow = getGradientColors({ c1: left, c2: right }, stops);
    // add in
    rowColors.push(nextRow);
  }

  return rowColors;
};
