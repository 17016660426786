import React from 'react';
import styled from '@emotion/styled';

export interface Artistic {
  width: string;
  height: string;
  color: string;
}

const Root = styled.div<Artistic>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ color }) => color};
`;

export const Rectangle = ({ width, height, color }: Artistic) => (
  <Root width={width} height={height} color={color} />
);
